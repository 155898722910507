import { getMinsFromNow } from "@/utils/getMinsFromNow";
import Link from "next/link";
import { useEffect, useState } from "react";
import { Item, Menu, useContextMenu } from "react-contexify";

const TaskCard = ({
  task,
  user,
  disabled,
  editable,
  setEditTaskId,
  setTaskToTerminate,
  isDag,
  forceSuccess,
}) => {
  const CTX_MENU_ID = `task-context-menu-${task.task_id}`;
  const DAG_CTX_MENU_ID = `dag-context-menu-${task.task_id}`;

  const { show } = useContextMenu({
    id: CTX_MENU_ID,
  });

  const handleContextMenu = (e, taskId) => {
    e.preventDefault();

    show({
      event: e,
      props: {
        taskId,
      },
    });
  };

  const { show: showDag } = useContextMenu({
    id: DAG_CTX_MENU_ID,
  });

  const handleDagContextMenu = (e, taskId) => {
    e.preventDefault();

    showDag({
      event: e,
      props: {
        taskId,
      },
    });
  };

  const [expand, setExpand] = useState(false);

  const [timeElapsed, setTimeElapsed] = useState(0);
  useEffect(() => {
    setTimeElapsed(getMinsFromNow(task.created_at));

    // update time elapsed every 1 minute
    const interval = setInterval(() => {
      setTimeElapsed(getMinsFromNow(task.created_at));
    }, 60000);

    return () => clearInterval(interval);
  }, [task.created_at]);

  // compute total pages
  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    if (task) {
      if (task.blob_id) setTotalPages(1);
      else {
        try {
          // reduce task.group.blobs.map(blob => blob.total_pages) to get total pages
          const totalPages = task.group?.blobs?.reduce(
            (acc, blob) => acc + blob?.total_pages,
            0
          );

          setTotalPages(totalPages);
        } catch (error) {
          console.log("Error getting total pages", error);
        }
      }
    }
  }, [task]);

  if (isDag)
    return (
      <>
        <Menu
          id={DAG_CTX_MENU_ID}
          animation={{ enter: "slide", exit: "slide" }}
          className="ctx-menu"
        >
          <Item
            id="Force Success"
            onClick={() => {
              forceSuccess(task);
            }}
          >
            Force Success
          </Item>
        </Menu>

        <div
          className="bg-gray-100 rounded-md drop-shadow-md py-2 px-4 flex flex-col gap-2"
          onContextMenu={(e) => {
            if (
              task?.type === "bsa/indexing" ||
              task?.type === "bsa/data-alignment"
            )
              handleDagContextMenu(e, task.task_id);
          }}
        >
          <div className="flex justify-between">
            <p className="font-semibold">
              Task {task?.parent_task?.task_id} in progress...
            </p>

            <div>
              <div
                className={`text-sm rounded-full px-2 w-fit capitalize text-[#6E6E6E] bg-[#D7D7D7]`}
              >
                DAG
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2 text-sm text-gray-600">
            <p>
              Task Type:{" "}
              <span className="font-light capitalize">
                {task.type
                  .replace("nwl/", "NWL ")
                  .replace("bsa/", "BSA ")
                  .replace("-", " ")}
              </span>
            </p>
          </div>

          <div
            className={`flex gap-2 items-center text-xs text-gray-600 mt-4 ${
              timeElapsed < 2
                ? ""
                : timeElapsed < 5
                ? "text-yellow-500"
                : "text-red-500"
            }`}
          >
            <svg
              className={`align-middle ${
                timeElapsed < 2
                  ? "stroke-[#6C757D]"
                  : timeElapsed < 5
                  ? "stroke-yellow-500"
                  : "stroke-red-500"
              }`}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_64_2456)">
                <path
                  d="M7.99998 4.00028V8.00029L10.6666 9.33362M14.6666 8.00029C14.6666 11.6822 11.6819 14.667 7.99998 14.667C4.31808 14.667 1.33331 11.6822 1.33331 8.00029C1.33331 4.31839 4.31808 1.33362 7.99998 1.33362C11.6819 1.33362 14.6666 4.31839 14.6666 8.00029Z"
                  // stroke="#6C757D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_64_2456">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0 0.000244141)"
                  />
                </clipPath>
              </defs>
            </svg>

            <p>
              {new Date(task.created_at).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </p>

            {/* 0-2, 2-5, >5 (yellow, orange, red) */}
            <p className={`ml-auto`}>{timeElapsed} mins. ago</p>
          </div>

          <div className="bg-gray-200 h-0.5 w-full"></div>

          <div className="flex justify-between">
            <div className="flex items-center gap-2 text-sm">
              <p className="font-light">Airflow</p>
            </div>

            <svg
              width="20"
              height="20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin"
            >
              <g
                clipPath="url(#a)"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#134074"
              >
                <path
                  opacity=".2"
                  d="M10 1.875a8.125 8.125 0 1 0 0 16.25 8.125 8.125 0 0 0 0-16.25ZM0 10a10 10 0 1 1 20 0 10 10 0 0 1-20 0Z"
                />
                <path d="M9.063.938A.937.937 0 0 1 10 0a10 10 0 0 1 10 10 .938.938 0 0 1-1.875 0A8.125 8.125 0 0 0 10 1.875a.937.937 0 0 1-.938-.938Z" />
              </g>
              <defs>
                <clipPath id="a">
                  <path fill="#fff" d="M0 0h20v20H0z" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </>
    );

  return (
    <>
      <Menu
        id={CTX_MENU_ID}
        animation={{ enter: "slide", exit: "slide" }}
        className="ctx-menu"
      >
        <Item
          id="Edit"
          onClick={() => {
            setEditTaskId(task.task_id);
          }}
        >
          Edit
        </Item>

        <Item
          id="Escalate"
          onClick={() => {
            console.log("Escalate", task);
            setTaskToTerminate(task);
          }}
        >
          Escalate
        </Item>
      </Menu>

      <div
        className="bg-gray-100 rounded-md drop-shadow-md py-2 px-4 flex flex-col gap-2"
        onContextMenu={(e) => {
          if (editable) handleContextMenu(e, task.task_id);
        }}
      >
        <div className="flex justify-between">
          <p className="font-semibold">Task {task.task_id}</p>

          <div>
            <div
              className={`text-sm rounded-full px-2 w-fit capitalize ${
                task.type.includes("splitter")
                  ? "bg-[#EABCF9]/40 text-[#78007D]"
                  : task.type.includes("indexing")
                  ? "bg-[#67D7F7]/40 text-[#005F9B]"
                  : task.type.includes("data-alignment")
                  ? "bg-[#75DAB0]/40 text-[#00693B]"
                  : task.type.includes("splicer")
                  ? "bg-orange-500/20 text-amber-700"
                  : task.type.includes("conditions")
                  ? "bg-[#B0ABAB]/40 text-[#555555]"
                  : "bg-red-500/20 text-red-700"
              }`}
            >
              {task.type
                .replace("nwl/", "NWL ")
                .replace("bsa/", "BSA ")
                .replace("-", " ")}
            </div>
          </div>
        </div>

        {task.group?.metadata?.resource_id && (
          <div className="flex flex-col gap-2 text-sm text-gray-600">
            {task.group?.metadata?.resource_id && (
              <p>
                Loan ID:{" "}
                <span className="font-light">
                  {task.group?.metadata?.resource_id}
                </span>
              </p>
            )}

            {expand ? (
              <>
                {task.group?.metadata?.app?.name && (
                  <p>
                    Account:{" "}
                    <span className="font-light">
                      {task.group?.metadata?.app?.name}
                    </span>
                  </p>
                )}

                {task.group?.metadata?.parameters?.borrower_name && (
                  <p>
                    Borrower Name:{" "}
                    <span className="font-light">
                      {task.group?.metadata?.parameters?.borrower_name}
                    </span>
                  </p>
                )}

                {task.group?.metadata?.parameters?.sales_branch?.name && (
                  <p>
                    Sales Branch:{" "}
                    <span className="font-light">
                      {task.group?.metadata?.parameters?.sales_branch?.name}
                    </span>
                  </p>
                )}

                {task.group?.metadata?.parameters?.income_doc_type && (
                  <p>
                    Income Doc Type:{" "}
                    <span className="font-light">
                      {task.group?.metadata?.parameters?.income_doc_type}
                    </span>
                  </p>
                )}

                {task.group?.metadata?.priority && (
                  <p>
                    Priority:{" "}
                    <span className="font-light">
                      {task.group?.metadata?.priority}
                    </span>
                  </p>
                )}

                {task.group?.tm_task_id && (
                  <p>
                    TM Task:{" "}
                    <span className="font-light">{task.group?.tm_task_id}</span>
                  </p>
                )}

                {totalPages > 0 && (
                  <p>
                    Total Pages:{" "}
                    <span className="font-light">{totalPages}</span>
                  </p>
                )}

                <p
                  className="text-blue-400 underline cursor-pointer"
                  onClick={() => {
                    setExpand(false);
                  }}
                >
                  Hide Details
                </p>
              </>
            ) : (
              <p
                className="text-blue-400 underline cursor-pointer"
                onClick={() => {
                  setExpand(true);
                }}
              >
                Expand Details
              </p>
            )}
          </div>
        )}

        {task.type.includes("nwl/") && task.group?.metadata?.loan_number && (
          <div className="flex flex-col gap-2 text-sm text-gray-600">
            <p>
              Loan Number:{" "}
              <span className="font-light">
                {task.group?.metadata?.loan_number}
              </span>
            </p>

            {task.group?.metadata?.borrower?.name && (
              <p>
                Borrower:{" "}
                <span className="font-light">
                  {task.group?.metadata?.borrower?.name}
                </span>
              </p>
            )}

            {task.group?.metadata?.lender?.name && (
              <p>
                Lender:{" "}
                <span className="font-light">
                  {task.group?.metadata?.lender?.name}
                </span>
              </p>
            )}

            {task.group?.metadata?.loan_officer?.name && (
              <p>
                Loan Officer:{" "}
                <span className="font-light">
                  {task.group?.metadata?.loan_officer?.name}
                </span>
              </p>
            )}
          </div>
        )}

        <div
          className={`flex gap-2 items-center text-xs text-gray-600 mt-4 ${
            task.status === "success"
              ? ""
              : timeElapsed < 2
              ? ""
              : timeElapsed < 5
              ? "text-yellow-500"
              : "text-red-500"
          }`}
        >
          <svg
            className={`align-middle ${
              task.status === "success"
                ? "stroke-[#6C757D]"
                : timeElapsed < 2
                ? "stroke-[#6C757D]"
                : timeElapsed < 5
                ? "stroke-yellow-500"
                : "stroke-red-500"
            }`}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_64_2456)">
              <path
                d="M7.99998 4.00028V8.00029L10.6666 9.33362M14.6666 8.00029C14.6666 11.6822 11.6819 14.667 7.99998 14.667C4.31808 14.667 1.33331 11.6822 1.33331 8.00029C1.33331 4.31839 4.31808 1.33362 7.99998 1.33362C11.6819 1.33362 14.6666 4.31839 14.6666 8.00029Z"
                // stroke="#6C757D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_64_2456">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(0 0.000244141)"
                />
              </clipPath>
            </defs>
          </svg>

          <p>
            {new Date(task.created_at).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </p>

          {/* 0-2, 2-5, >5 (yellow, orange, red) */}
          <p className={`ml-auto`}>{timeElapsed} mins. ago</p>
        </div>

        {task.end_time && task.status === "success" && (
          <div className="flex gap-2 items-center text-xs text-gray-600">
            <p>&#10003;</p>

            <p>
              {new Date(parseInt(task.end_time)).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </p>
          </div>
        )}

        <div className="bg-gray-200 h-0.5 w-full"></div>

        <div className="flex justify-between">
          <div className="flex items-center gap-2 text-sm">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2.00024C6.477 2.00024 2 6.47724 2 12.0002C2 17.5232 6.477 22.0002 12 22.0002C17.523 22.0002 22 17.5232 22 12.0002C22 6.47724 17.523 2.00024 12 2.00024ZM8.5 9.50024C8.5 9.04062 8.59053 8.58549 8.76642 8.16085C8.94231 7.73621 9.20012 7.35037 9.52513 7.02537C9.85013 6.70036 10.236 6.44256 10.6606 6.26667C11.0852 6.09077 11.5404 6.00024 12 6.00024C12.4596 6.00024 12.9148 6.09077 13.3394 6.26667C13.764 6.44256 14.1499 6.70036 14.4749 7.02537C14.7999 7.35037 15.0577 7.73621 15.2336 8.16085C15.4095 8.58549 15.5 9.04062 15.5 9.50024C15.5 10.4285 15.1312 11.3187 14.4749 11.9751C13.8185 12.6315 12.9283 13.0002 12 13.0002C11.0717 13.0002 10.1815 12.6315 9.52513 11.9751C8.86875 11.3187 8.5 10.4285 8.5 9.50024ZM18.258 16.9842C17.5092 17.9256 16.5575 18.6857 15.4739 19.2079C14.3904 19.7302 13.2029 20.001 12 20.0002C10.7971 20.001 9.60965 19.7302 8.52607 19.2079C7.44249 18.6857 6.49081 17.9256 5.742 16.9842C7.363 15.8212 9.575 15.0002 12 15.0002C14.425 15.0002 16.637 15.8212 18.258 16.9842Z"
                fill="#475467"
              />
            </svg>

            <p className="font-light">
              {user.first_name} {user.last_name}
            </p>
          </div>

          {task.status === "success" || disabled ? null : (
            <Link href={`/${task.type}/${task.task_id}`} className="text-2xl">
              <svg
                width="30"
                height="22"
                viewBox="0 0 30 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.25 11L23.75 11M23.75 11L15 4.58335M23.75 11L15 17.4167"
                  stroke="#134074"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default TaskCard;
