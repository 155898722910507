import { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";

import TaskCard from "@/components/dashboard/TaskCard";
import Layout from "@components/Layout";
import { useAuthContext } from "@/context/AuthContext";
import Modal from "@/components/Modal";
import useSessionStorage from "@/hooks/useSessionStorage";
import AlertModal from "@/components/AlertModal";
import { TASK_TYPE_OPTIONS as taskTypeOptions } from "@/utils/TASK_TYPE_OPTIONS";
import useLocalStorage from "@/hooks/useLocalStorage";

const taskDateOptions = [
  { value: "all", label: "All Time" },
  { value: "24h", label: "Last 24 Hours" },
  { value: "7d", label: "This Week" },
];

const taskSortOptions = [
  { value: "latest", label: "Newest" },
  { value: "earliest", label: "Oldest" },
];

const terminateReasonOptions = [
  {
    label: "Missing Page",
    value: "MissingPage",
  },
  {
    label: "Copy Not Clear",
    value: "CopyNotClear",
  },
  {
    label: "Truncated",
    value: "Truncated",
  },
  {
    label: "Language Not Supported",
    value: "LanguageNotSupported",
  },
  {
    label: "Other Exception Remarks",
    value: "OtherExceptionRemarks",
  },
];

export const getServerSideProps = async (ctx) => {
  const lenders = await fetch(
    `${process.env.NEXT_PUBLIC_NWL_BASE_URL}/api/lenders`
  ).then((res) => {
    if (!res.ok) {
      return null;
    }

    return res.json();
  });

  return {
    props: {
      // sort lenders by alphabetical order and format using lender id and name
      lenders: lenders
        ? lenders
            .sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            })
            .map((lender) => ({
              value: lender.id,
              label: lender.name,
            }))
        : [],
    },
  };
};

export default function Home({ lenders: data }) {
  const { user } = useAuthContext();

  const [doneTasks, setDoneTasks] = useLocalStorage("doneTasks", []);

  const [adminView, setAdminView] = useSessionStorage("adminView", false);
  const [alertModalOpen, setAlertModalOpen] = useState("");

  const [tasks, setTasks] = useState([]);
  const [dagTasks, setDagTasks] = useState([]);
  const [lenderOptions, setLenderOptions] = useState(data);

  // Use useEffect to check for localStorage on the client-side
  const previousEtag = useMemo(() => {
    if (typeof window !== "undefined") {
      // Check if we are in a browser environment
      return localStorage.getItem("tasksEtag");
    }
    return null; // Return null during server-side rendering
  }, [tasks]);

  const getTasks = useCallback(async () => {
    console.log("Fetching tasks...");

    if (user) {
      try {
        console.log("Tasks", tasks);

        // Only send the ETag if tasks are already in the state (check for an existing state)
        const shouldUseCache = tasks && tasks.length > 0;

        if (shouldUseCache) console.log("Using cache...");

        const response = await fetch(
          `/api/users/${user.user_id}/tasks?role=${user.role}&status=pending,ongoing`,
          {
            method: "GET",
            headers: {
              "If-None-Match": shouldUseCache ? previousEtag || "" : "",
            },
          }
        );

        if (response.status === 304) {
          console.log("No new tasks");
          return;
        }

        const responseData = await response.json();
        console.log("Tasks response data", responseData);
        const { tasks: tempTasks, dagTasks: tempDagTasks } = responseData;

        // Store the new ETag in localStorage
        const etag = response.headers.get("ETag");
        if (etag) {
          localStorage.setItem("tasksEtag", etag);
        }

        // Sort and update tasks in the state
        const sortedTasks = tempTasks.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        console.log("Fetched tasks", sortedTasks);

        setTasks(sortedTasks); // Update your state with the new tasks
        setDagTasks(
          tempDagTasks.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          )
        );
      } catch (error) {
        console.error(error);
      }
    }
  }, [previousEtag, user]); // useCallback dependencies

  useEffect(() => {
    getTasks();

    const interval = setInterval(() => {
      getTasks();
    }, 20000);

    return () => clearInterval(interval);
  }, [getTasks]);

  const [agentOptions, setAgentOptions] = useState([]);

  const getDoneTasks = async (ids) => {
    const url = `/api/tasks?ids=${ids.join(",")}`;
    const tempDoneTasks = await fetch(url).then((res) => res.json());

    setDoneTasks(tempDoneTasks);
  };

  useEffect(() => {
    console.log("Done tasks", doneTasks);

    // remove tasks that are older than 24 hours
    const filteredDoneTasks = doneTasks
      .filter((task) => task.end_time)
      .filter(
        (task) =>
          new Date(parseInt(task.end_time)).getTime() >
          Date.now() - 24 * 60 * 60 * 1000
      );

    if (filteredDoneTasks.length)
      getDoneTasks(filteredDoneTasks.map((task) => task.task_id));
    else setDoneTasks([]);

    // set task type, task date, and task sort filters based on stored filters
    // for task type options, some may be found inside "options" array
    const taskTypeOption = taskTypeOptions.find(
      (option) => option.value === storedFilters.type
    );

    let foundTaskType = null;
    if (taskTypeOption) {
      foundTaskType = taskTypeOption;
    } else {
      taskTypeOptions
        .filter((op) => op.options)
        .forEach((option) => {
          const found = option.options.find(
            (innerOption) => innerOption.value === storedFilters.type
          );

          if (found) {
            foundTaskType = found;
          }
        });
    }

    setSelectedTaskType(foundTaskType);

    setSelectedTaskDate(
      taskDateOptions.find((option) => option.value === storedFilters.date) || {
        value: "24h",
        label: "Last 24 Hours",
      }
    );

    setSelectedTaskSort(
      taskSortOptions.find((option) => option.value === storedFilters.sort) || {
        value: "earliest",
        label: "Oldest",
      }
    );
  }, []);

  useEffect(() => {
    if (user && user.role.includes("manager")) {
      fetch(`/api/users?role=agent,manager,supervisor,nwl-agent,nwl-manager`)
        .then((res) => res.json())
        .then((data) => {
          setAgentOptions(
            data
              .sort((a, b) => {
                // sort by last_name
                if (a.last_name < b.last_name) return -1;
                if (a.last_name > b.last_name) return 1;
                return 0;
              })
              .map((agent) => ({
                value: agent.user_id,
                label: `${agent.last_name}, ${agent.first_name}`,
                data: agent,
              }))
          );
        });
    }
  }, [user]);

  /* EDIT */
  // #region Edit
  const [editTaskId, setEditTaskId] = useState(null);
  const [editedTask, setEditedTask] = useState(null);
  const [editTaskLoading, setEditTaskLoading] = useState(false);

  useEffect(() => {
    if (editTaskId) {
      setEditedTask(tasks.find((task) => task.task_id === editTaskId));
    } else {
      setEditedTask(null);
    }
  }, [editTaskId]);

  const editTask = async (task) => {
    setEditTaskLoading(true);

    try {
      const response = await fetch(`/api/tasks/${task.task_id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: task.user_id,
        }),
      });

      if (response.ok) {
        const updatedTask = await response.json();

        setTasks((prev) =>
          prev.map((t) =>
            t.task_id === updatedTask.task_id
              ? {
                  ...task,
                  status: "pending",
                }
              : t
          )
        );
      }

      setEditTaskId(null);
    } catch (error) {
      console.error(error);
      const logId = Date.now();
      setAlertModalOpen(
        `An error occurred. Please try again. Log ID: ${logId}`
      );

      fetch("/api/logger", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          `[LOG ID: ${logId}] ${error.stack || error.message}`
        ),
      });
    }

    setEditTaskLoading(false);
  };
  // #endregion

  /* TERMINATE */
  // #region Terminate
  const [taskToTerminate, setTaskToTerminate] = useState(null);
  const [terminateTaskLoading, setTerminateTaskLoading] = useState(false);

  const terminateTask = async (task) => {
    setTerminateTaskLoading(true);

    const body = {
      tm_task_id: task.group.tm_task_id,
      process_type: task.group.metadata.process_type,
      reason:
        task.terminateReason == "Other Exception Remarks"
          ? task.terminateRemarks
          : task.terminateReason,
      user_id: user.user_id,
    };

    console.log("Terminating task", body);

    // hit endpoint /api/tasks/[taskId]/escalate
    try {
      const response = await fetch(`/api/tasks/${task.task_id}/escalate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const updatedTask = await response.json();

        setTasks((prev) =>
          prev.map((t) => (t.task_id === updatedTask.task_id ? updatedTask : t))
        );
      }

      setAlertModalOpen("Task escalated successfully");
      setTaskToTerminate(null);
    } catch (error) {
      console.error(error);
      const logId = Date.now();
      setAlertModalOpen(
        `An error occurred. Please try again. Log ID: ${logId}`
      );

      fetch("/api/logger", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          `[LOG ID: ${logId}] ${error.stack || error.message}`
        ),
      });
    }

    setTerminateTaskLoading(false);
  };
  // #endregion

  /* FORCE SUCCESS */
  // #region Force Success
  const forceSuccess = async (task) => {
    console.log("Force Success", task);

    if (task.type === "bsa/indexing" || task.type === "bsa/data-alignment") {
      // hit /api/tasks/[taskId]/force
      const res = await fetch(`/api/tasks/${task.task_id}/force`, {
        method: "POST",
      });

      if (!res.ok) {
        console.error("Error in forceSuccess:", res.statusText);
      }

      const data = await res.json();
      console.log("Force Success Response:", data);

      // update tasks
      getTasks();
    }
  };
  // #endregion

  /* FILTERS */
  // #region Filters
  const [selectedTaskType, setSelectedTaskType] = useState(null);
  const [selectedTaskDate, setSelectedTaskDate] = useState(null);
  const [selectedTaskSort, setSelectedTaskSort] = useState(null);

  // NWL Filter
  const [selectedNWLLender, setSelectedNWLLender] = useState(null);

  const [storedFilters, setStoredFilters] = useSessionStorage(
    "dashboardFilters",
    {
      type: null,
      date: "24h",
      sort: "earliest",
    }
  );

  const getFilteredTasks = (t, status, isDag) => {
    return (
      t
        .filter((task) => {
          let isValid = true;

          if (selectedTaskType?.value) {
            // if selectedTaskType ends with /, filter by prefix. otherwise, filter by exact match
            isValid =
              isValid && selectedTaskType.value === "subprep/"
                ? !task.type.includes("/")
                : selectedTaskType.value.endsWith("/")
                ? task.type.startsWith(selectedTaskType.value)
                : task.type === selectedTaskType.value;
          }

          if (selectedTaskDate?.value) {
            const date =
              status == "ongoing"
                ? new Date(parseInt(task.start_time))
                : status == "success"
                ? new Date(parseInt(task.end_time))
                : new Date(task.created_at);

            switch (selectedTaskDate.value) {
              case "all":
                break;
              case "7d":
                isValid =
                  isValid &&
                  date.getTime() > Date.now() - 7 * 24 * 60 * 60 * 1000;
                break;
              default:
                // default: 24h
                isValid =
                  isValid && date.getTime() > Date.now() - 24 * 60 * 60 * 1000;
                break;
            }
          }

          return isValid;
        })
        .sort((a, b) => {
          if (selectedTaskSort?.value === "latest") {
            if (status == "pending")
              return new Date(b.created_at) - new Date(a.created_at);
            else if (status == "ongoing")
              return (
                new Date(parseInt(b.start_time)) -
                new Date(parseInt(a.start_time))
              );
            else if (status == "success")
              return (
                new Date(parseInt(b.end_time)) - new Date(parseInt(a.end_time))
              );

            return new Date(b.created_at) - new Date(a.created_at);
          } else {
            if (status == "pending")
              return new Date(a.created_at) - new Date(b.created_at);
            else if (status == "ongoing")
              return (
                new Date(parseInt(a.start_time)) -
                new Date(parseInt(b.start_time))
              );
            else if (status == "success")
              return (
                new Date(parseInt(a.end_time)) - new Date(parseInt(b.end_time))
              );

            return new Date(a.created_at) - new Date(b.created_at);
          }
        })
        // nwl lender filter
        .filter((task) => {
          if (task.type.startsWith("nwl/")) {
            if (selectedNWLLender?.label) {
              return (
                task.group?.metadata?.lender?.id ===
                  selectedNWLLender.value ||
                selectedNWLLender.value === null
              );
            }
          }

          return true;
        })
        .filter((task) => {
          if (isDag)
            return adminView ? true : task.parent_task.user_id === user.user_id;

          return adminView ? true : task.user_id === user.user_id;
        })
    );
  };

  useEffect(() => {
    setStoredFilters({
      type: selectedTaskType?.value,
      date: selectedTaskDate?.value,
      sort: selectedTaskSort?.value,
    });
  }, [selectedTaskType, selectedTaskDate, selectedTaskSort]);
  // #endregion

  return (
    <Layout>
      <AlertModal
        alertModalOpen={alertModalOpen}
        setAlertModalOpen={setAlertModalOpen}
      />

      <Modal
        hidden={!editTaskId}
        maxHeight="90dvh"
        width="30dvw"
        overflowY="unset"
      >
        {editedTask ? (
          <div className="flex flex-col gap-4 justify-center w-full">
            <div className="text-left flex flex-col gap-2">
              <p className="font-semibold text-lg">Task {editedTask.task_id}</p>

              <p className="font-semibold">
                Task Type:{" "}
                <span className="font-normal capitalize">
                  {editedTask.type.replace("bsa/", "BSA ").replace("-", " ")}
                </span>
              </p>

              {editedTask.group?.tm_task_id ? (
                <p className="font-semibold">
                  TM Task:{" "}
                  <span className="font-normal">
                    {editedTask.group?.tm_task_id}
                  </span>
                </p>
              ) : null}

              <p className="font-semibold">
                Created:{" "}
                <span className="font-normal">
                  {new Date(editedTask.created_at).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </span>
              </p>

              <div className="flex gap-2 items-center">
                <p className="font-semibold">Agent: </p>

                <Select
                  className="z-50"
                  isSearchable
                  options={agentOptions}
                  value={
                    editedTask.user &&
                    agentOptions.find(
                      (agent) => agent.data.user_id === editedTask.user.user_id
                    )
                  }
                  onChange={(selectedOption) => {
                    setEditedTask((prev) => ({
                      ...prev,
                      user: selectedOption.data,
                      user_id: selectedOption.value,
                    }));
                  }}
                  styles={{
                    indicatorSeparator: () => ({ display: "none" }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#9CA3AF",
                    }),
                    control: (provided) => ({
                      ...provided,
                      width: "200px",
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#F5F5F5",
                      primary: "#0C3C60",
                    },
                  })}
                />
              </div>
            </div>

            <div className="bg-gray-200 h-0.5 w-full" />

            <div
              className={`flex gap-4 w-full justify-center ${
                editTaskLoading ? "animate-pulse cursor-not-allowed" : ""
              }`}
            >
              <button
                className={`w-fit rounded-md px-4 py-1 border border-dark-blue text-dark-blue cursor-pointer`}
                onClick={() => {
                  setEditTaskId(null);
                }}
              >
                Close
              </button>

              <button
                className={`w-fit rounded-md px-4 py-1 bg-dark-blue text-white cursor-pointer disabled:cursor-not-allowed disabled:opacity-50`}
                disabled={
                  tasks.find((task) => task.task_id === editTaskId)?.user_id ===
                  editedTask.user_id
                }
                onClick={() => {
                  if (!editTaskLoading) editTask(editedTask);
                }}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}
      </Modal>

      <Modal
        hidden={!taskToTerminate}
        maxHeight="90dvh"
        width="30dvw"
        overflowY="unset"
      >
        {taskToTerminate ? (
          <div className="flex flex-col gap-4 justify-center w-full">
            <div className="text-left flex flex-col gap-2">
              <p className="font-semibold text-lg">Request: Escalate</p>

              <div className="flex gap-2 items-center">
                <p className="font-semibold">Agent Assigned: </p>

                {taskToTerminate.user &&
                  agentOptions.find(
                    (agent) =>
                      agent.data.user_id === taskToTerminate.user.user_id
                  ).label}
              </div>

              <p className="font-semibold">
                Task Type:{" "}
                <span className="font-normal capitalize">
                  {taskToTerminate.type
                    .replace("bsa/", "BSA ")
                    .replace("-", " ")}
                </span>
              </p>

              {taskToTerminate.group?.tm_task_id ? (
                <p className="font-semibold">
                  TM Task:{" "}
                  <span className="font-normal">
                    {taskToTerminate.group?.tm_task_id}
                  </span>
                </p>
              ) : null}

              <div className="flex gap-2 items-center">
                <p className="font-semibold">Reason: </p>

                <Select
                  className="z-50"
                  isSearchable
                  options={terminateReasonOptions}
                  value={terminateReasonOptions.find(
                    (reason) => reason.label == taskToTerminate.terminateReason
                  )}
                  onChange={(selectedOption) => {
                    setTaskToTerminate((prev) => ({
                      ...prev,
                      terminateReason: selectedOption.label,
                      terminateRemarks: "",
                    }));
                  }}
                  styles={{
                    indicatorSeparator: () => ({ display: "none" }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#9CA3AF",
                    }),
                    control: (provided) => ({
                      ...provided,
                      width: "200px",
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#F5F5F5",
                      primary: "#0C3C60",
                    },
                  })}
                />
              </div>

              {taskToTerminate.terminateReason ===
                "Other Exception Remarks" && (
                <div className="flex gap-2 items-center">
                  <p className="font-semibold">Remarks: </p>

                  <textarea
                    className="w-full border border-gray-300 rounded-md p-2"
                    value={taskToTerminate.terminateRemarks}
                    onChange={(e) => {
                      setTaskToTerminate((prev) => ({
                        ...prev,
                        terminateRemarks: e.target.value,
                      }));
                    }}
                  />
                </div>
              )}
            </div>

            <div className="bg-gray-200 h-0.5 w-full" />

            <div
              className={`flex gap-4 w-full justify-center ${
                terminateTaskLoading ? "animate-pulse cursor-not-allowed" : ""
              }`}
            >
              <button
                className={`w-fit rounded-md px-4 py-1 border border-dark-blue text-dark-blue cursor-pointer`}
                onClick={() => {
                  setTaskToTerminate(null);
                }}
              >
                Close
              </button>

              <button
                className={`w-fit rounded-md px-4 py-1 bg-dark-blue text-white cursor-pointer disabled:cursor-not-allowed disabled:opacity-50`}
                onClick={() => {
                  if (!terminateTaskLoading) terminateTask(taskToTerminate);
                }}
                disabled={
                  terminateTaskLoading ||
                  !taskToTerminate.terminateReason ||
                  (taskToTerminate.terminateReason ===
                    "Other Exception Remarks" &&
                    !taskToTerminate.terminateRemarks)
                }
              >
                Confirm
              </button>
            </div>
          </div>
        ) : null}
      </Modal>

      <div className="flex flex-col gap-3 h-full">
        {/* Header Row */}
        <div className="flex flex-col w-full gap-4">
          {(user?.role.includes("manager") ||
            user?.role.includes("supervisor")) && (
            <label className="z-20 bg-white rounded-md inline-flex items-center cursor-pointer px-2 py-1 ml-auto">
              <input
                type="checkbox"
                checked={adminView}
                onChange={() => {
                  setAdminView((prev) => !prev);
                }}
                className="sr-only peer"
              />
              <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-dark-blue"></div>
              <span className="ms-3 text-sm text-grey-100 pr-2 capitalize">
                {user.role.replace("nwl-", "NWL ")} View
              </span>
            </label>
          )}

          <div className="flex justify-between items-center">
            <div className="flex flex-row gap-1 items-center text-center">
              <p className="text-xl font-large">Active Tasks</p>
              <p className="text-slate-800 font-medium w-5 text-center rounded-full">
                {
                  tasks.filter((task) =>
                    adminView ? true : task.user_id === user.user_id
                  ).length
                }
              </p>
            </div>

            {/* Filters */}
            <div className="flex flex-col gap-2">
              <div className="flex gap-2 text-sm">
                {/* TASK TYPE */}
                <Select
                  options={taskTypeOptions}
                  value={selectedTaskType || taskTypeOptions[0]}
                  onChange={setSelectedTaskType}
                  placeholder="Task Type"
                  isSearchable
                  styles={{
                    indicatorSeparator: () => ({ display: "none" }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#9CA3AF",
                    }),
                    control: (provided) => ({
                      ...provided,
                      width: "160px",
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#F5F5F5",
                      primary: "#0C3C60",
                    },
                  })}
                />

                {/* DATE */}
                <Select
                  options={taskDateOptions}
                  value={selectedTaskDate || taskDateOptions[1]}
                  onChange={setSelectedTaskDate}
                  placeholder="Task Date"
                  isSearchable
                  styles={{
                    indicatorSeparator: () => ({ display: "none" }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#9CA3AF",
                    }),
                    control: (provided) => ({
                      ...provided,
                      width: "160px",
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#F5F5F5",
                      primary: "#0C3C60",
                    },
                  })}
                />

                {/* SORT */}
                <Select
                  options={taskSortOptions}
                  value={selectedTaskSort || taskSortOptions[1]}
                  onChange={setSelectedTaskSort}
                  placeholder="Sort"
                  isSearchable={false}
                  styles={{
                    indicatorSeparator: () => ({ display: "none" }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#9CA3AF",
                    }),
                    control: (provided) => ({
                      ...provided,
                      width: "110px",
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#F5F5F5",
                      primary: "#0C3C60",
                    },
                  })}
                />
              </div>

              {/* NWL FILTERS */}
              {selectedTaskType?.value?.startsWith("nwl/") && (
                <div className="flex gap-2 text-sm w-fit ml-auto">
                  {/* LENDER */}
                  <Select
                    options={[
                      { value: null, label: "All Lenders" },
                      ...lenderOptions,
                    ]}
                    value={selectedNWLLender}
                    onChange={setSelectedNWLLender}
                    placeholder="Lender"
                    isSearchable
                    styles={{
                      indicatorSeparator: () => ({ display: "none" }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#9CA3AF",
                      }),
                      control: (provided) => ({
                        ...provided,
                        width: "160px",
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#F5F5F5",
                        primary: "#0C3C60",
                      },
                    })}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex flex-row grow gap-2">
          {/* PENDING */}
          <div className="flex flex-col bg-white rounded-md h-full w-1/3">
            {/* Header Row */}
            <div className="px-4 py-1.5 items-center rounded-t-md justify-between my-2">
              <div className="flex gap-2 items-center">
                <p className="text-slate-800 text-lg font-medium">
                  Not Started
                </p>
                <p className="text-slate-800 text-sm font-medium px-2 text-center bg-slate-300 rounded-full">
                  {
                    getFilteredTasks(
                      tasks.filter((task) => task.status === "pending"),
                      "pending"
                    ).length
                  }
                </p>
              </div>
              <div className="bg-gray-500 h-0.5 w-full mt-2"></div>
            </div>

            {/* Content */}
            <div className="p-4 flex flex-col gap-4 h-[75dvh] overflow-y-auto">
              {getFilteredTasks(
                tasks.filter((task) => task.status === "pending"),
                "pending"
              ).map((task, index) => (
                <TaskCard
                  key={index}
                  task={task}
                  user={task.user || user}
                  disabled={
                    // disable if user has at least 1 ongoing task
                    (user.role === "agent" &&
                      tasks
                        .filter((task) => task.status === "ongoing")
                        .filter((task) =>
                          adminView ? true : task.user_id === user.user_id
                        ).length > 0) ||
                    (user.role === "supervisor" &&
                      task.user_id !== user.user_id)
                  }
                  editable={user.role.includes("manager")}
                  setEditTaskId={setEditTaskId}
                  setTaskToTerminate={setTaskToTerminate}
                />
              ))}
            </div>
          </div>

          {/* ONGOING */}
          <div className="flex flex-col bg-white rounded-md h-full w-1/3">
            {/* Header Row */}
            <div className="px-4 py-1.5 items-center rounded-t-md justify-between my-2">
              <div className="flex gap-2 items-center">
                <p className="text-slate-800 text-lg font-medium">Ongoing</p>
                <p className="text-slate-800 text-sm font-medium px-2 text-center bg-slate-300 rounded-full">
                  {
                    getFilteredTasks(
                      tasks.filter((task) => task.status === "ongoing"),
                      "ongoing"
                    ).length
                  }
                </p>
              </div>
              <div className="bg-gray-500 h-0.5 w-full mt-2"></div>
            </div>

            {/* Content */}
            <div className="p-4 flex flex-col gap-4 h-[75dvh] overflow-y-auto">
              {getFilteredTasks(dagTasks, "ongoing", true).map(
                (task, index) => (
                  <TaskCard
                    key={index}
                    task={task}
                    isDag
                    forceSuccess={forceSuccess}
                    editable={user.role.includes("manager")}
                  />
                )
              )}

              {getFilteredTasks(
                tasks.filter((task) => task.status === "ongoing"),
                "ongoing"
              ).map((task, index) => (
                <TaskCard
                  key={index}
                  task={task}
                  user={task.user || user}
                  editable={user.role.includes("manager")}
                  setEditTaskId={setEditTaskId}
                  disabled={
                    user.role === "supervisor" && task.user_id !== user.user_id
                  }
                  setTaskToTerminate={setTaskToTerminate}
                />
              ))}
            </div>
          </div>

          {/* DONE */}
          <div className="flex flex-col bg-white rounded-md h-full w-1/3">
            {/* Header Row */}
            <div className="px-4 py-1.5 items-center rounded-t-md justify-between my-2">
              <div className="flex gap-2 items-center">
                <p className="text-slate-800 text-lg font-medium">Done</p>
                <p className="text-slate-800 text-sm font-medium px-2 text-center bg-slate-300 rounded-full">
                  {getFilteredTasks(doneTasks, "success").length}
                </p>
              </div>
              <div className="bg-gray-500 h-0.5 w-full mt-2"></div>
            </div>

            {/* Content */}
            <div className="p-4 flex flex-col gap-4 h-[75dvh] overflow-y-auto">
              {getFilteredTasks(doneTasks, "success")
                .sort((a, b) => {
                  const aFinish = a.end_time
                    ? new Date(parseInt(a.end_time))
                    : new Date(a.created_at);

                  const bFinish = b.end_time
                    ? new Date(parseInt(b.end_time))
                    : new Date(b.created_at);

                  return bFinish - aFinish;
                })
                .map((task, index) => (
                  <TaskCard
                    key={index}
                    task={task}
                    user={task.user || user}
                    setEditTaskId={setEditTaskId}
                    disabled
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
